.video-player-container {
  display: flex;
  flex-direction: column;
}

video {
  border-radius: 24px;
}

.share-container {
  position: relative;
  align-self: flex-end;
  margin: 1% 1%;
}

.share-container > .share-modal-toggler {
  display: flex;
  line-height: 2.3;
  font-family: "SackersGothicStd-Heavy";
  font-weight: 600;
  font-size: clamp(14px,2vw,18px);
  padding-right: 20px;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  padding-top: 3%;
}
.share-container > .share-modal-toggler:after {
  margin-left: 7px;
}

.share-container > .share-modal-toggler:after {
  content: url("../../assets/images/share-arrow-icon.svg");
  height: 52px;
  margin-left: 7px;
  width: clamp(16px,5vw,28px);
}

.share-container > .modal-share {
  align-items: center;
  background-color: #331715;
  border-radius: 12px;
  bottom: 62px;
  color: #f7f0e4;
  display: flex;
  flex-direction: column;
  max-width: 96vw;
  padding: 14px 0 40px;
  position: absolute;
  right: 0;
}

.share-container > .modal-share > span.text {
  font-family: "SackersGothicStd-Heavy";
  font-style: Heavy;
  font-weight: 600;
  line-height: auto;
  font-size: 18px;
  text-transform: uppercase;
}

.share-container > .modal-share > .icons-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  padding: 6vw 40px;
  /* gap: 40px; */
}

/* .share-container > .modal-share > .icons-container > a {
  margin-right: 40px;
} */
.share-container > .modal-share > .icons-container > a {
  margin-right: 20px;
}

.share-container > .modal-share > .icons-container > a > .email {
  display: block;
  /* width: 70px; */
  height: 35px;
  width: 28px;
  background: url("../../assets/images/email-icon.svg") no-repeat;
}

.share-container > .modal-share > .icons-container > a > .twitter {
  display: block;
  /* width: 61.5px; */
  width: 28px;
  height: 35px;
  background: url("../../assets/images/twitter-icon.svg") no-repeat;
}

.share-container > .modal-share > .icons-container > a > .facebook {
  display: block;
  /* width: 24.5px; */
  width: 12px;
  height: 35px;
  background: url("../../assets/images/facebook-icon.svg") no-repeat;
}

.share-container > .modal-share > .icons-container > a > .instagram {
  display: block;
  /* width: 50px; */
  width: 24px;
  height: 35px;
  background: url("../../assets/images/instagram-icon.svg") no-repeat;
}

.share-container > .modal-share > .icons-container > a > .linked-in {
  display: block;
  /* width: 50px; */
  width: 22px;
  height: 35px;
  background: url("../../assets/images/linkedin-icon.svg") no-repeat;
}

.share-container > .modal-share > .share-link-container {
  display: flex;
  flex-direction: column;
  min-width: 296px;
  padding: 0 40px;
}

.share-container > .modal-share > .share-link-container > input {
  background-color: #f7f0e4;
  border-radius: 4px;
  color: #331715;
  font-family: "Century Gothic";
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 8px 10px;
}
.share-container > .modal-share > .share-link-container > button {
  background-color: #f7a686;
  border-radius: 4px;
  color: #f7f0e4;
  font-family: "SackersGothicStd-Heavy";
  font-size: 24px;
  font-weight: 600;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: -2px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .share-container>.modal-share>.icons-container>a {
    margin-right: 26px;
  }

  .share-container>.modal-share>.icons-container>a>.email {
    height: 35px;
    width: 28px;
    background: url("../../assets/images/email-icon.svg") no-repeat;
  }

  .share-container > .modal-share > .icons-container > a > .twitter {
    display: block;
    height: 35px;
    width: 28px;
    background: url("../../assets/images/twitter-icon.svg") no-repeat;
  }

  .share-container > .modal-share > .icons-container > a > .facebook {
    display: block;
    height: 35px;
    width: 12px;
    background: url("../../assets/images/facebook-icon.svg") no-repeat;
  }

  .share-container > .modal-share > .icons-container > a > .instagram {
    display: block;
    height: 35px;
    width: 24px;
    background: url("../../assets/images/instagram-icon.svg") no-repeat;
  }

  .share-container > .modal-share > .icons-container > a > .linked-in {
    display: block;
    height: 35px;
    width: 22px;
    background: url("../../assets/images/linkedin-icon.svg") no-repeat;
  }
  .share-container > .modal-share {
    display: flex;
    align-items: flex-start;
    padding: 14px 40px 70px 24px;
    min-width: 0;
  }
  .share-container > .modal-share > .share-link-container {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
  }
  .share-container > .modal-share > .share-link-container > input {
    background-color: #f7f0e4;
    border-radius: 4px;
    color: #331715;
    font-family: "Century Gothic";
    font-size: 14px;
    font-weight: 700;
    min-width: 200px;
    padding: 15px 0 15px 10px;
  }

  .share-container>.modal-share>.share-link-container>button {
    font-size: clamp(14px,4.5vw,18px);
    margin: 1px 0 1px -2px!important;
    padding: 8px;
  }

  .share-container > .modal-share > span.text {
    font-size: 18px;
  }
  .share-container > .modal-share > .icons-container {
    padding: 6vw 0px;
  }
  .share-container > .modal-share > .icons-container > a {
    margin-right: 26px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .share-container>.modal-share {
    background-color: #331715;
    border-radius: 12px;
    bottom: 70px;
    color: #f7f0e4;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    padding: 14px 14px 20px 18px;
    position: absolute;
    right: -5px;
  }

  /* .share-container > .modal-share {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -5px;
    bottom: 70px;
    background-color: #331715;
    color: #f7f0e4;
    border-radius: 12px;
    max-width: 100vw;
    padding: 14px 40px 70px 24px;
  } */
  .share-container > .modal-share > .icons-container {
    display: flex;
    align-items: baseline;
    padding: 10px 0;
  }
}
