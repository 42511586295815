html,
body {
  width: 100%;
  /* height: 100%; */
  margin: 0;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

@font-face {
  font-family: "Century Gothic";
  src: url("assets/fonts/GOTHIC.TTF");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Century Gothic";
  src: url("assets/fonts/GOTHICB0.TTF");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SackersGothicStd-Heavy";
  src: url("assets/fonts/SackersGothicStd-Heavy.otf");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.slide img {
  width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hidden {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

#video-player {
  width: 100%;
  margin: 0 auto;
}

footer {
  background-color: #331715;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 220px;
  padding: 0 20px;
}

.footer_detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 80px 0;
  gap: 20px;
}

.footer_detail > h1 {
  font-family: "SackersGothicStd-Heavy";
  color: #f7f0e4;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.5;
}

.footer_detail > .social_icons > ul {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  /* Flex-gap doesn't work on older ios safari */
  /* gap: 80px; */
}
.footer_detail > .social_icons > ul > li:first-child {
  margin-right: 80px;
}
.footer_detail > .social_icons img {
  width: 40px;
  height: 40px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .footer_detail > h1 {
    font-size: 2rem;
    line-height: auto;
  }
}
