main {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #f7f0e4;
  /* z-index: 10; */
  padding: 54px 10%;
  color: #331715;
}
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

main > .course-title {
  font-family: "SackersGothicStd-Heavy";
  font-size: 36px;
  font-weight: 600;
  align-self: center;
  text-align: center;
  text-transform: uppercase;
}

main > .course-description {
  font-family: Century Gothic;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 12px;
  padding-bottom: 40px;
  letter-spacing: 16%;
  line-height: 24px;
  font-size: 16px;
}

main > .video-player-container {
  display: flex;
  flex-direction: column;
}

main > .video-player-container .share-container {
  position: relative;
  align-self: flex-end;
}
main > .video-player-container .video-jaw-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -12.5%;
}
main > .video-player-container .video-jaw-container .video-name {
  font-family: "Century Gothic";
  letter-spacing: 0.1em;
  color: #000;

  margin-bottom: auto;
  margin-top: 14px;
  font-size: 14px;
  margin-left: -10%;
}
main > .video-player-container > .share-container > .share-modal-toggler {
  display: flex;
  gap: 7px;
  line-height: 2.3;
  font-family: "SackersGothicStd-Heavy";
  font-weight: 600;
  font-size: 36px;
}

main > .video-player-container > .share-container > .share-modal-toggler:after {
  content: url("../../assets/images/share-arrow-icon.svg");
  width: 65px;
  height: 52px;
}

main > .video-player-container > .share-container > .modal-share {
  display: flex;

  flex-direction: column;
  position: absolute;
  right: -30px;
  bottom: 85px;
  background-color: #331715;
  color: #f7f0e4;
  border-radius: 12px;
  max-width: 100vw;
  padding: 14px 40px 70px 24px;
}

main > .video-player-container > .share-container > .modal-share > span.text {
  font-family: "SackersGothicStd-Heavy";
  font-style: Heavy;
  font-weight: 600;
  line-height: auto;
  font-size: 32px;
  text-transform: uppercase;
}

main > .video-player-container > .share-container > .modal-share > .icons-container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 40px 0;
  /* gap: 40px; */
}

main
  > .video-player-container
  > .share-container
  > .modal-share
  > .icons-container
  > a
  > .email {
  display: block;
  width: 70px;
  height: 50px;
  background: url("../../assets/images/email-icon.svg") no-repeat;
}

main
  > .video-player-container
  > .share-container
  > .modal-share
  > .icons-container
  > a
  > .twitter {
  display: block;
  width: 61.5px;
  height: 50px;
  background: url("../../assets/images/twitter-icon.svg") no-repeat;
}

main
  > .video-player-container
  > .share-container
  > .modal-share
  > .icons-container
  > a
  > .facebook {
  display: block;
  width: 24.5px;
  height: 50px;
  background: url("../../assets/images/facebook-icon.svg") no-repeat;
}

main
  > .video-player-container
  > .share-container
  > .modal-share
  > .icons-container
  > a
  > .instagram {
  display: block;
  width: 50px;
  height: 50px;
  background: url("../../assets/images/instagram-icon.svg") no-repeat;
}

main
  > .video-player-container
  > .share-container
  > .modal-share
  > .icons-container
  > a
  > .linked-in {
  display: block;
  width: 50px;
  height: 50px;
  background: url("../../assets/images/linkedin-icon.svg") no-repeat;
}

main
  > .video-player-container
  > .share-container
  > .modal-share
  > .share-link-container {
  display: flex;
  flex-direction: column;
}

main
  > .video-player-container
  > .share-container
  > .modal-share
  > .share-link-container
  > input {
  font-family: Century Gothic;
  font-weight: bold;
  font-size: 24px;
  padding: 15px 66px 15px 10px;
  background-color: #f7f0e4;
  color: #331715;
  border-radius: 4px;
}
main
  > .video-player-container
  > .share-container
  > .modal-share
  > .share-link-container
  > button {
  font-family: "SackersGothicStd-Heavy";
  font-weight: 600;
  font-size: 36px;
  padding: 8px;
  background-color: #f7a686;
  color: #f7f0e4;
  border-radius: 4px;
}

main > .video-player-container > .carousel-container {
  display: none;
  flex-direction: column;
}

main > .video-player-container .carousel-controls {
  display: none;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
}

main > .video-player-container .carousel-controls button.backward {
  display: block;
  width: clamp(24px, 3vw, 65px);

  height: 40px;
  background: url("../../assets/images/arrow-left-icon.svg") no-repeat;
}

main > .video-player-container .carousel-controls button.forward {
  display: block;
  width: clamp(24px, 3vw, 65px);
  height: 40px;
  background: url("../../assets/images/arrow-right-icon.svg") no-repeat;
  background-position: right;
}

/* Mobile video selector */
main .share-modal-toggler {
  padding-right: 0px !important;
}
main #video-player {
  width: 101vw;
  margin-left: -12.5% !important;
}

.react-player__preview {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  min-height: 430px;
}

main #video-player video {
  border-radius: 0;
}
main .mobile-slides-container {
  margin-bottom: 64px;
  margin-left: -12.5%;
  margin-right: -12.5%;
}
main .mobile-slides-container .slide {
  overflow: hidden;
  border-radius: 0;
}

main .mobile-slides-container div.slide-container {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  height: auto;
}
main .mobile-slides-container div.watch-button-container {
  display: flex;
  align-items: center;
  background: #331815;
}

main .mobile-slides-container div.watch-button-container span {
  border-radius: 8px;
  padding: 10px 1em;
}

main .mobile-slides-container .slide-thumbnail-container {
  margin-bottom: -4px;
  padding: 10% 8% 0;
  background-color: #f7a686;
}
main .mobile-slides-container img {
  border-radius: 12px 12px 0 0 !important;

  box-shadow: 0px 0px 12px 4px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 0px 0px 12px 4px rgb(0 0 0 / 30%);
  -moz-box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.3);
}
main .mobile-slides-container .slide-description {
  border-radius: 0 !important;
}
/* End Mobile video selector */

/* Hide scrollbar on Chrome, Safari, Opera */
main .scroll-container::-webkit-scrollbar {
  display: none;
}
main .scroll-container {
  padding-left: 32px;
  height: 400px;
  width: 100vw;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  /* Hide scrollbar on IE, Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
main .carousel-items {
  position: absolute;
  height: 100%;
  width: 400%;
  display: flex;
  white-space: nowrap;
  /* Flex-gap doesn't work on older ios safari */
  /* gap: 40px; */
  padding: 28px 0;
  min-height: 430px;
}

main .carousel-items > div.slide-container {
  margin-right: 40px;
}

main .carousel-items:focus {
  outline: none;
}
main div.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  object-fit: cover;
  height: 100%;
  background-size: 100%;
  margin-bottom: 0px !important;
  width: 100%;
}

main div.slide:first-child {
  /* margin-left: 32px; */
}

main div.slide-container {
  width: 400px;
  height: 255px;
  max-width: 100vw;
}
main .carousel-container div.slide-container:nth-child(3) .slide {
  background-position-y: -14px;
}

main div.watch-button-container {
  display: flex;
  max-height: 100px;
  align-items: center;
  justify-content: center;
}

main span.watch {
  border-radius: 8px;
  background-color: #f7a686;
  font-family: "SackersGothicStd-Heavy";
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 2em;
  margin-top: 330px;
}

main span.watch > p {
  margin-bottom: -3px;
}

main div.slide img.slide-thumbnail {
  width: 100%;
  border-radius: 25px;
}

main div.slide .slide-description {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 0px 0px 18px 18px;
  background-color: #331715;
  overflow: hidden;
}
main div.slide .slide-description > p {
  font-family: "Century Gothic";
  color: #f7f0e4;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.16em;
  text-overflow: scroll;
}
main div.slide .slide-description > .title {
  font-family: "Century Gothic";
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: 0.16em;
  color: #f7f0e4;
  font-size: 16px;
}

@media (max-width: 576px) {
  .react-player__preview {
    min-height: 55vw;
  }
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  main div.slide img {
    min-height: 183px;
  }
  main div.slide-container {
    min-width: 430px;
    height: 480px;
  }
  main span.watch {
    font-size: 18px;
    padding: 10px 60px;
  }
  main > .course-description {
    text-align: left;
  }
  main > .video-player-container .video-jaw-container .video-name {
    font-size: 14px;
  }

  main .carousel-container div.slide-container .slide-description {
    margin-top: 220px;
    z-index: 2;
  }
  main .carousel-container .slide-thumbnail-container {
    position: absolute;
    height: 70%;
  }
  main .carousel-container .slide-thumbnail-container > img {
    border-radius: 12px 12px 0 0;
  }
  main .scroll-container {
    height: 600px;
  }
}

@media (min-width: 768px) {
  main .mobile-slides-container {
    display: none;
  }
  main .carousel-container {
    display: flex !important;
  }
  main .carousel-controls {
    display: flex !important;
  }
  main > .video-player-container {
    align-items: center;
    width: 80vw;
    margin-left: 0 !important;
  }
  main #video-player {
    width: 80vw;
    max-width: 768px;
    margin-left: 0 !important;
  }
  main #video-player video {
    border-radius: 24px !important;
  }
  main > .video-player-container .video-jaw-container {
    margin-right: 0 !important;
  }
  main > .video-player-container .video-jaw-container .video-name {
    margin-bottom: auto;
    font-size: 16px;
    margin-left: 0 !important;
  }
}
