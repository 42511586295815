* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.header_outer {
  background: rgba(55, 18, 17, 0.8);
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 5;
  transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  line-height: 1.2;
}

.header_inner {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 13px 0px 0;
  transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.logo a {
  max-width: 100px;
  display: block;
  transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.fixed_header .header_inner {
  padding: 20px 0px 25px;
}

.fixed_header .header_outer {
  background: rgba(55, 18, 17, 0.8);
}

.fixed_header .header_inner {
  /* padding: 20px 0px 25px; */
}

.fixed_header .logo a {
  max-width: 80px;
}

.fixed_header .menuNav ul li {
  padding-left: 20px;
}

.auto_container {
  position: relative;
  margin: auto;
  max-width: 1440px;
  padding: 0 20px;
}

.logo {
  width: 12%;
  min-width: 160px;
}
.logo img {
  height: 23px !important;
}

.menuIcon,
.closeNav {
  display: none;
}

.menuNav {
  width: 77% !important;
  text-align: right;
}

.menuIcon,
.closeNav {
  display: none;
}

.menuNav ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0px;
  padding-inline-start: 40px;
}

.menuNav ul li {
  padding-left: 25px;
  transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}
.menuNav ul li a {
  font-family: "Open Sans", sans-serif;
  font-size: 18px !important;
  text-decoration: none;
  display: block;
  color: #f2eadc;
  text-transform: uppercase;
  position: relative;
}

.menuNav ul li a::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -50%;
  width: 0%;
  height: 2px;
  background-color: #f2eadc;
  transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.menuNav ul li:hover a::after {
  width: 100%;
}

.menuNav ul li.active a::after {
  width: 100%;
}

.menuNav ul li.menuActive a::after {
  width: 100%;
}

.menuNav ul li {
  padding-top: 10px !important;
  text-align: -webkit-match-parent;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .menuNav ul {
    display: block;
    padding-top: 35%;
    padding-inline-start: 0;
  }
  .menuNav ul li {
    padding: 0px 20px 40px;
  }
  .menuNav ul li a {
    color: #371211;
    display: inline-block;
  }
  .menuIcon,
  .menu_clos {
    display: block;
    cursor: pointer;
  }
  .menuIcon,
  .menuIcon:after {
    position: absolute;
    transition: all 0.4s ease;
  }
  .menuIcon:after {
    width: 80%;
    height: 2px;
    background: #f2eadc;
    content: "";
    display: block;
    right: 0;
    top: 7px;
  }
  .menuIcon:hover:after {
    right: 20%;
  }
  .menuIcon {
    width: 30px;
    height: 20px;
    right: 20px;
    top: 17px;
    border-bottom: 2px solid #f2eadc;
    border-top: 2px solid #f2eadc;
    z-index: 2;
    transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
  }
  .menuNav {
    width: 100%;
    text-align: left;
    position: fixed;
    left: 0px;
    top: 0px;
    /* max-height: 400px; */
    max-height: 0vh;
    transition: max-height 0.4s ease-in-out;
    height: 100%;
    background: #fd9b79;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(253, 155, 121);
    z-index: 2;
    overflow: hidden;
    /* display: none; */
  }
  .menuNav ul li a::after {
    background-color: #371211;
  }
  .open {
    max-height: 100vh;
  }
  .closeNav {
    display: block;
    background: url("../../assets/images/closeNav.png") no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    color: #fff;
    right: 20px;
    top: 30px;
    background-size: 100%;
  }
}
