/* mixins, variables, etc. */

$dark-brand: #331715;
$light-brand: #f7f0e4;
$alt-light-brand: #f7a686;
$alt-dark-brand: #796b64;
$button-hover-color: #d88f74;
$off-light-text: #c0b7b3;

div.navbar.navbar-fixed-top.navbar-inverse {
  background-color: $dark-brand;
  height: 80px;

  #header-logo {
    float: left;
    margin-right: 10px;
    font-size: 1.7em;
    color: $light-brand;
    text-transform: uppercase;
    letter-spacing: -1px;
    padding-top: 20px;
    font-weight: bold;
    &:hover {
      color: $alt-light-brand;
      text-decoration: none;
    }
    svg {
      path {
        fill: $light-brand;
      }
    }
  }

  nav {
    .navbar-header {
      .navbar-toggle {
        border: 0 none;
        box-shadow: none;
        text-shadow: none;
        margin: 24px 0px 0px 0px;
        &:hover {
          background: $light-brand;
          .icon-bar {
            background: $dark-brand;
          }
        }
        &:focus {
          background: $dark-brand;
          .icon-bar {
            background: $light-brand;
          }
        }
        .icon-bar {
          background: $light-brand;
        }
      }
    }
  }

  .navbar-link {
    min-width: 135px;
    width: auto;
    padding-top: 10px;

    &.dropdown {
      padding-top: 12px;

      @media (min-width: 768px) {
        padding-top: 10px;
      }

      > .account-name {
        color: #f7f0e4;
        font-weight: bold;

        span {
          @media (max-width: 567px) {
            margin: 4px 6px 0px 0px;
            display: block;
            width: auto;
            float: right;
          }
        }
      }

      @media (max-width: 567px) {
        &.login.open {
          svg path {
            stroke: #331715;
          }

          span {
            color: #796b64;
          }
        }
      }

      &.login {
        width: auto;
        min-width: 168px;
        max-width: 250px;

        .dropdown-menu {
          width: 100%;
        }

        .account-name {
          @media (min-width: 768px) {
            position: relative;

            svg {
              position: absolute;
              right: -15px;
              bottom: 17px;
            }
          }
        }
      }

      a {
        font-family: "CGP", "Century Gothic", CenturyGothic, AppleGothic,
          sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $alt-dark-brand;
        display: block;

        svg {
          height: 24px;
          float: right;

          path {
            stroke: $light_brand;
          }
        }
      }
    }

    &.dropdown.open {
      .dropdown-toggle.account-name {
        background: unset;
        z-index: 9999;
      }
      .dropdown-menu {
        background: $light-brand;
        border-radius: 0px 0px 8px 8px;

        li {
          padding-top: 10px;

          a {
            width: 100%;
            height: 100%;
            margin: 0px;
            padding-top: 8px;
            &:hover {
              background: $alt-dark-brand;
              color: $light-brand;
            }
          }
          &.divider,
          &.log-out-link {
            padding-top: 0px;
          }
        }
      }
    }

    &.active a {
      background-color: unset;
      .head-nav-text {
        border-bottom: 4px solid $light-brand;
        padding-bottom: 8px;
      }
    }

    a {
      font-family: "CGP", "Century Gothic", CenturyGothic, AppleGothic,
        sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: $light-brand;
      display: block;
      text-decoration: none;
    }
  }
  .navbar-sign-up-link {
    margin-right: 16px;
    padding-top: 10px;

    a {
      border-radius: 8px;
      border: $alt-light-brand solid 1px;
      color: $alt-light-brand;
      font-family: "CGP", "Century Gothic", CenturyGothic, AppleGothic,
        sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      // height: 48px;
      line-height: 22px;
      position: relative;
      text-align: center;
      width: 103px;
    }
  }

  .navbar-login-link {
    padding-top: 10px;

    a {
      background: $alt-light-brand;
      border: $alt-light-brand solid 1px;
      border-radius: 8px;
      color: $dark-brand;
      font-family: "CGP", "Century Gothic", CenturyGothic, AppleGothic,
        sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      // height: 48px;
      line-height: 22px;
      position: relative;
      text-align: center;
      width: 103px;
    }
  }
}

@media (max-width: 768px) {
  .navbar-collapse {
    background: #331715;
    text-align: center;
    padding-bottom: 20px;
  }
  .navbar-sign-up-link {
    margin-right: 0 !important;
  }
  .navbar-sign-up-link a {
    padding: 15px;
    margin: 0px auto;
  }
  
  .navbar-login-link {
    margin-right: 0 !important;
  }
  .navbar-login-link a {
    padding: 15px;
    margin: 0px auto;
  }
}