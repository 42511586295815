header {
  position: relative;
  display: grid;
  min-height: 430px;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  z-index: -10;
  padding: 50px 1rem 0 1.5rem;
}

header > img {
  position: absolute;
  min-width: 600px;
  max-width: 80%;
  margin-bottom: -70px;
  z-index: -10;
}

header > .header-description-container {
  display: flex;
  flex-direction: column;
  color: #f7f0e4;
  margin-bottom: -100px;
  padding-bottom: 30px;
}

header > .header-description-container > .title {
  font-family: "SackersGothicStd-Heavy";
  font-weight: 600;
  text-transform: uppercase;
  font-size: 48px;
}

header > .header-description-container > .description {
  font-family: Century Gothic;
  font-size: 18px;
}

header > .header-description-container > .description-sub {
  font-family: Century Gothic;
  font-size: 18px;
  margin-top: 18px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  header > .header-description-container {
    padding-bottom: 0px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  header {
    grid-template-columns: 1fr 1fr;
  }
  header > img {
    object-fit: cover;
    position: relative;
    min-width: 400px;
    min-height: 340px;
  }
  header > .header-description-container {
    padding-bottom: 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  header > img {
    /* margin-bottom: -50%; */
    min-width: 600px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  header {
    grid-template-columns: 1fr 1fr;
  }
  header > img {
    object-fit: cover;
    bottom: 0;
    /* margin-bottom: -40%; */
  }
}

@media (min-width: 1586px) {
  header > img {
    object-fit: cover;
    position: relative;
    max-width: 640px;
    margin-left: 20%;
  }
}
